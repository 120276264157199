<template>
  <default-layout>
    <div class="news__container">
      <div class="news--latest">
        <h1>{{ $t("post.news") }}</h1>
        <div
          class="news__card"
          v-for="n in displayedPosts"
          :key="n.id"
          @click="changeRoute(n.id)"
        >
          <div class="news__picture">
            <img :src="n.thumbnail" />
          </div>
          <div>
            <h3>{{ n.title }}</h3>
            <time pubdate :datetime="$date(n.date).format('YYYY-MM-DD')">{{
              $date(n.date).format("DD/MM/YYYY")
            }}</time>
            <p class="news__detail">{{ n.detail | stripHTML | limitText }}</p>
          </div>
        </div>
        <pagination
          :page="page"
          :pages="pages"
          :per-page="perPage"
          :data-length="news[$i18n.locale].length"
          @changePage="page = $event"
          @next="page++"
          @previous="page--"
        ></pagination>
      </div>
      <div class="news--notify">
        <h1>{{ $t("post.notify") }}</h1>
        <div v-for="noti in notify[$i18n.locale]" :key="noti.id">
          <h3 @click="showDialog(noti)">{{ noti.title }}</h3>
          <time pubdate :datetime="$date(noti.date).format('YYYY-MM-DD')">{{
            $date(noti.date).format("DD/MM/YYYY")
          }}</time>
        </div>
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layout/DefaultLayout";
import Pagination from "@/components/Pagination";
export default {
  data() {
    return {
      news: { en: [], vi: [] },
      count: 0,
      page: 1,
      perPage: 5,
      pages: [],
      notify: {},
    };
  },
  methods: {
    changeRoute(id) {
      this.$router.push(`/news/${id}`);
    },
    showDialog(noti) {
      this.$swal({
        title: noti.title,
        html: noti.detail,
        width: 600,
        padding: "3em",
      });
    },
    // counter(command) {
    //   command === -1
    //     ? (this.count -= this.perPage)
    //     : (this.count += this.perPage);
    // },
    fetchPost() {
      return this.$axios.get(`/api/posts`);
    },
  },
  computed: {
    /**
     * Số yêu cầu sẽ hiện trong 1 trang
     * @returns {Array} - Số dữ liệu sẽ hiện thị
     */
    displayedPosts() {
      return this.news.length !== 0
        ? this.$helpers.paginate(
            this.news[this.$i18n.locale],
            this.page,
            this.perPage
          )
        : [];
    },
  },
  watch: {
    news() {
      this.pages = this.$helpers.setPages(
        this.news[this.$i18n.locale].length,
        this.perPage
      );
    },
    "$i18n.locale": function () {
      this.pages = this.$helpers.setPages(
        this.news[this.$i18n.locale].length,
        this.perPage
      );
    },
  },
  async created() {
    this.$swal({
      title: "Loading",
      showConfirmButton: false,
      allowOutsideClick: false,
      timer: 10000,
      onOpen: () => {
        this.$swal.showLoading();
      },
    });
    try {
      const posts = await this.fetchPost();
      const notify = await this.$axios.get("/api/posts/notify");
      this.notify = notify.data;
      this.news = posts.data;
      this.$swal.close();
    } catch (err) {
      this.$swal.close();
    }
  },
  components: {
    DefaultLayout,
    Pagination,
  },
};
</script>

<style lang="scss">
@import "~@/assets/styles/news.scss";
</style>